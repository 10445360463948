import React, { useState, useEffect } from "react";
import { Host, Port } from "../Routes/apiConfig";
import { ResponsiveContainer } from "recharts"; // Import ResponsiveContainer
import {
  Table,
  Spin,
  DatePicker,
  Button,
  Modal,
  Form,
  Checkbox,
  message,
  Input,
  Space,
  Layout,
  Upload,
  Card,
  Badge,
  Select,
  Flex 
} from "antd";
import { useNavigate } from "react-router-dom";
import {
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import Cookies from "js-cookie";
import moment from "moment";
import Axios from "axios";
import { useLayout } from "../LayoutContext";
import MyHeader from "../compo/Header";
import './pages-css/GamesWithoutEvents.css'
import { useTranslation } from "react-i18next";

const GamesWithoutEvents = ({colorTheme}) => {

  const { isLtr } = useLayout();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const sessionKey = Cookies.get("sessionKeySwin");
  useEffect(() => {
    const sessionKey = Cookies.get("sessionKeySwin");
    if (!sessionKey) {
      navigate("/login");
    }
  }, [navigate]);
  const headers={
    headers: {
      "hyazs-session-key": sessionKey,
      "HYAZS-API-KEY":
        "rYJ1riRCUqRHMwQH47UCOYwPnfigX1X-aeeTVYyxVTSWKsSup0VXS-K1",
    },
  }
  /*********************************************************/
  const [data, setData] = useState([]);
  const [correctAmbiguous, setCorrectAmbiguous] = useState([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [processAmbiguousLoading, setProcessAmbiguousLoading] = useState(false);
  /*********************************************************/
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  /**********************************************************/
  const fetchData = async (offSet = 0, pageSize = 10,date) => {
    console.log("The limit is :", pageSize, "and the offset is:", offSet);
    try {
      setLoading(true);
      const gamesApi=`${Host}:${Port}/admin/predictions/no_events_games`;

      const response = await Axios.get(gamesApi,headers);

      if (response.data.statusCode < 0 && response.data.statusCode !== -101) {
        message.error(response.data.message);
      } else if (response.data.statusCode === -101) {
        Cookies.remove("sessionKeySwin");
        navigate("/login");
      } else {
        setData(Array.isArray(response.data.data) ? response.data.data : [response.data.data]);
        // setTotalItems(response.data.data.totalCount);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading to false once the data is fetched
    }
  };
  /*********************************************************/
  const [uploadModalVisible, setUploadModalVisible] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [competitorValue, setCompetitorValue] = useState(null);
  const [firstScore, setFirstScore] = useState(null);
  const [bothTeamScore, setBothTeamScore] = useState(false);
  const [gameGoals, setGameGoals] = useState(null);


  const openUploadModal = (gameId,data) => {
    setRowData(data);
    setUploadModalVisible(true);
  };

  const closeUploadModal = () => {
    setUploadModalVisible(false);
    form.resetFields(); // Reset form fields
  };
  const correctAmbiguousHandleHeaders = {
    headers: {
      "hyazs-session-key": sessionKey,
      "HYAZS-API-KEY": "rYJ1riRCUqRHMwQH47UCOYwPnfigX1X-aeeTVYyxVTSWKsSup0VXS-K1",
    },
  };

  /*********************************************************/
  const competitorValueHandle =async (gameId,winner,firstScore,bothTeamScore,gameGoals)=>{
    await correctAmbiguousHandle(gameId,winner,firstScore,bothTeamScore,gameGoals);
  }
  const correctAmbiguousHandle = async (id, winner,first_score,both_team_score,game_goals) => {
    const body = {
      game:id,
      winner:winner,
      first_score: first_score,
      both_team_score: both_team_score?both_team_score:false,
      game_goals: game_goals
    }
    try {
      setProcessAmbiguousLoading(true);
      const response = await Axios.post(`${Host}:${Port}/admin/predictions/process_event`, body,correctAmbiguousHandleHeaders);
      if (response.data.statusCode === -101) {
        Cookies.remove("sessionKeySwin");
        navigate("/login");
      }else {
        closeUploadModal();
        message.success("Game managed successfully!");
      }
    } catch (error) {
      console.error("Process Error:", error);
    }finally {
      setProcessAmbiguousLoading(false); // Set loading to false when upload is completed or encounters an error
    }
  };
  /*********************************************************/

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
    
    // Calculate the new offset based on the page and limit

    const newOffset = (page - 1) * pageSize;
    
    fetchData(newOffset, pageSize, selectedDate);
    console.log("new offset",newOffset,"new page size", pageSize)
  };
  

  useEffect(() => {
    fetchData();
  }, []);

  

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      // sorter: (a, b) => a.key - b.key,
      // onFilter: (value, record) => record.id.toString().includes(value), // Use 'id' here
      // filterDropdown: ({
      //   setSelectedKeys,
      //   selectedKeys,
      //   confirm,
      //   clearFilters,
      // }) => (
      //   <div style={{ padding: 8 }}>
      //     <Input
      //       placeholder="Search ID"
      //       value={selectedKeys[0]}
      //       onChange={(e) =>
      //         setSelectedKeys(e.target.value ? [e.target.value] : [])
      //       }
      //       onPressEnter={() => confirm()}
      //       style={{ width: 188, marginBottom: 8, display: "block" }}
      //     />
      //     <Button
      //       type="primary"
      //       onClick={() => confirm()}
      //       icon={<SearchOutlined />}
      //       size="small"
      //       style={{ width: 90, marginRight: 8 }}
      //     >
      //       Search
      //     </Button>
      //     <Button
      //       onClick={() => clearFilters()}
      //       size="small"
      //       style={{ width: 90 }}
      //     >
      //       Reset
      //     </Button>
      //   </div>
      // ),
      // filterIcon: <SearchOutlined style={{ fontSize: "18px" }} />,
    },
    {
      title: t("formLabels.gamesInfo.home_competitor_id"),
      dataIndex: "home_competitor_id",
      key: "home_competitor_id",
      render: (value) => {
        return value !== null ? value : "none";
      },
      // sorter: (a, b) => a.value - b.value,
      // onFilter: (value, record) =>
      //   record.away.toLowerCase().includes(value.toLowerCase()),
      // filterDropdown: ({
      //   setSelectedKeys,
      //   selectedKeys,
      //   confirm,
      //   clearFilters,
      // }) => (
      //   <div style={{ padding: 8 }}>
      //     <Input
      //       placeholder={t("formLabels.gamesInfo.search_away")}
      //       value={selectedKeys[0]}
      //       onChange={(e) =>
      //         setSelectedKeys(e.target.value ? [e.target.value] : [])
      //       }
      //       onPressEnter={() => confirm()}
      //       style={{ width: 188, marginBottom: 8, display: "block" }}
      //     />
      //     <Button
      //       type="primary"
      //       onClick={() => confirm()}
      //       icon={<SearchOutlined />}
      //       size="small"
      //       style={{ width: 90, marginRight: 8 }}
      //     >
      //       Search
      //     </Button>
      //     <Button
      //       onClick={() => clearFilters()}
      //       size="small"
      //       style={{ width: 90 }}
      //     >
      //       Reset
      //     </Button>
      //   </div>
      // ),
      // filterIcon: <SearchOutlined style={{ fontSize: "18px" }} />,
    },
    {
      title: t("formLabels.gamesInfo.home_competitor_name"),
      dataIndex: "home_competitor_name",
      key: "home_competitor_name",
      render: (value) => {
        return value !== null ? value : "none";
      },
      // sorter: (a, b) => a.value - b.value,
      // onFilter: (value, record) =>
      //   record.home.toLowerCase().includes(value.toLowerCase()), // Use 'email' here
      // filterDropdown: ({
      //   setSelectedKeys,
      //   selectedKeys,
      //   confirm,
      //   clearFilters,
      // }) => (
      //   <div style={{ padding: 8 }}>
      //     <Input
      //       placeholder={t("formLabels.gamesInfo.search_home")}
      //       value={selectedKeys[0]}
      //       onChange={(e) =>
      //         setSelectedKeys(e.target.value ? [e.target.value] : [])
      //       }
      //       onPressEnter={() => confirm()}
      //       style={{ width: 188, marginBottom: 8, display: "block" }}
      //     />
      //     <Button
      //       type="primary"
      //       onClick={() => confirm()}
      //       icon={<SearchOutlined />}
      //       size="small"
      //       style={{ width: 90, marginRight: 8 }}
      //     >
      //       Search
      //     </Button>
      //     <Button
      //       onClick={() => clearFilters()}
      //       size="small"
      //       style={{ width: 90 }}
      //     >
      //       Reset
      //     </Button>
      //   </div>
      // ),
      // filterIcon: <SearchOutlined style={{ fontSize: "18px" }} />,
    },
    {
      title: t("formLabels.gamesInfo.away_competitor_id"),
      dataIndex: "away_competitor_id",
      key: "away_competitor_id",
      render: (value) => {
        return value !== null ? value : "none";
      },
      // sorter: (a, b) => a.value - b.value,
      // onFilter: (value, record) => record.phone?.toString().includes(value), // Use 'mobile' here
      // filterDropdown: ({
      //   setSelectedKeys,
      //   selectedKeys,
      //   confirm,
      //   clearFilters,
      // }) => (
      //   <div style={{ padding: 8 }}>
      //     <Input
      //       placeholder={t("formLabels.gamesInfo.search_season_num")}
      //       value={selectedKeys[0]}
      //       onChange={(e) =>
      //         setSelectedKeys(e.target.value ? [e.target.value] : [])
      //       }
      //       onPressEnter={() => confirm()}
      //       style={{ width: 188, marginBottom: 8, display: "block" }}
      //     />
      //     <Button
      //       type="primary"
      //       onClick={() => confirm()}
      //       icon={<SearchOutlined />}
      //       size="small"
      //       style={{ width: 90, marginRight: 8 }}
      //     >
      //       Search
      //     </Button>
      //     <Button
      //       onClick={() => clearFilters()}
      //       size="small"
      //       style={{ width: 90 }}
      //     >
      //       Reset
      //     </Button>
      //   </div>
      // ),
      // filterIcon: <SearchOutlined style={{ fontSize: "18px" }} />,
    },
    {
      title: t("formLabels.gamesInfo.away_competitor_name"),
      dataIndex: "away_competitor_name",
      key: "away_competitor_name",
      render: (value) => {
        return value !== null ?value : "none";
      },
      // onFilter: (value, record) => {
      //   const filterDate = moment(value).startOf("day");
      //   const recordDate = moment(record.start_time).startOf("day");
      //   return filterDate.isSame(recordDate);
      // },
//       filterDropdown: ({
//         setSelectedKeys,
//         selectedKeys,
//         confirm,
//         clearFilters,
//       }) => (
//         <div style={{ padding: 8 }}>
//           <DatePicker
//             placeholder={t("formLabels.gamesInfo.start_time")}
//             value={selectedKeys[0] ? moment(selectedKeys[0]) : null}
//             onChange={(date) =>{
//               setSelectedDate(date.format("YYYY-MM-DD"));
//               setSelectedKeys(date ? [date.format("YYYY-MM-DD")] : []);
//             }}
//             onPressEnter={() => confirm()}
//             style={{ width: 188, marginBottom: 8, display: "block" }}
//           />
//           <Button
//             type="primary"
//             onClick={() => {confirm()
//               if(selectedDate){
//                 fetchData(null,null,selectedDate);
//               }
//               }}
//             icon={<SearchOutlined />}
//             size="small"
//             style={{ width: 90, marginRight: 8 }}
//           >
//             Search
//           </Button>
//           <Button
//           onClick={() => {
//             clearFilters();
//             setSelectedDate(null);
//             fetchData();
//             confirm();
//           }}
//           size="small"
//           style={{ width: 90 }}
//         >
//           Reset
// </Button>
//         </div>
//       ),
//       filterIcon: <SearchOutlined style={{ fontSize: "18px" }} />,
    },
    {
      title: t("formLabels.gamesInfo.game_time"),
      dataIndex: "game_time",
      key: "game_time",
      render: (value) => {
        return value !== null
          ? moment(value).format("YYYY-MM-DD HH:mm:ss")
          : "none";
      },
      // onFilter: (value, record) => {
      //   const filterDate = moment(value).startOf("day");
      //   const recordDate = moment(record.start_time).startOf("day");
      //   return filterDate.isSame(recordDate);
      // },
//       filterDropdown: ({
//         setSelectedKeys,
//         selectedKeys,
//         confirm,
//         clearFilters,
//       }) => (
//         <div style={{ padding: 8 }}>
//           <DatePicker
//             placeholder={t("formLabels.gamesInfo.start_time")}
//             value={selectedKeys[0] ? moment(selectedKeys[0]) : null}
//             onChange={(date) =>{
//               setSelectedDate(date.format("YYYY-MM-DD"));
//               setSelectedKeys(date ? [date.format("YYYY-MM-DD")] : []);
//             }}
//             onPressEnter={() => confirm()}
//             style={{ width: 188, marginBottom: 8, display: "block" }}
//           />
//           <Button
//             type="primary"
//             onClick={() => {confirm()
//               if(selectedDate){
//                 fetchData(null,null,selectedDate);
//               }
//               }}
//             icon={<SearchOutlined />}
//             size="small"
//             style={{ width: 90, marginRight: 8 }}
//           >
//             Search
//           </Button>
//           <Button
//           onClick={() => {
//             clearFilters();
//             setSelectedDate(null);
//             fetchData();
//             confirm();
//           }}
//           size="small"
//           style={{ width: 90 }}
//         >
//           Reset
// </Button>
//         </div>
//       ),
//       filterIcon: <SearchOutlined style={{ fontSize: "18px" }} />,
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          <Button
            className="custom-upload-button"
            type="primary"
            onClick={() => openUploadModal(record.id,record)}
          >
            {t("table.process")}
          </Button>
        </Space>
      ),
    },
  ];
  const customTableHeader = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <span
        style={{
          background: "linear-gradient(to right, #7684af, #21daff)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          fontSize: "28px",
          fontWeight: "bold",
        }}
      >
        {t("menu.GamesWithoutEvents")}
      </span>
    </div>
  );
  return (
    <Layout dir={isLtr ? "ltr" : "rtl"}>
      {/* <Flex wrap gap="small" vertical> */}
      <div className={`modern-table-container ${colorTheme==="dark"?"dark-mode":''}`}>
        <MyHeader
          // DrawerTitle="This is the drawer title"
          // DrawerFooter="This is the drawer footer"
          HeadercolorTheme={colorTheme}
        />

        <Table
          className="custom-table"
          columns={columns}
          dataSource={data}
          rowKey="id"
          // pagination={{
          //   current: currentPage,
          //   pageSize: pageSize,
          //   total: totalItems,
          //   onChange: handlePageChange,
          // }}
          rowClassName={(record) => {
            switch (record.is_updated) {
              case true:
                return 'highlight-updated';
              case false: // Handle the case where is_updated is false
                return '';
              default: // Handle the case where is_updated is undefined
                return ''; 
            }
          }}
          pagination={{ defaultPageSize: 10 }}
          loading={loading}
          title={customTableHeader}
          indicator={
            <Spin
              tip="Loading..."
              size="large"
              style={{ display: "flex", justifyContent: "center" }}
            />
          }
          scroll={{ x: '100%' }}
        />
        <Modal
          title="Manage Game"
          open={uploadModalVisible}
          onCancel={closeUploadModal}
          footer={null}
          >
          <Form
            form={form} // Pass the form instance
            layout="vertical"
            onFinish={(values) => competitorValueHandle(rowData.id,competitorValue,firstScore,bothTeamScore,gameGoals)}
            >
            <Form.Item
              label={t("formLabels.gamesInfo.gamesDetails.winner")}
              name="winner"
              rules={[
                {
                  required: true,
                  message: "Please select the Winner!",
                },
              ]}
              >
              <Select onChange={(value) => setCompetitorValue(value)}>
                {rowData && rowData.home_competitor_id && (
                  <Select.Option value={rowData.home_competitor_id}>{rowData.home_competitor_name}</Select.Option>
                )}
                {rowData && rowData.away_competitor_id && (
                  <Select.Option value={rowData.away_competitor_id}>{rowData.away_competitor_name}</Select.Option>
                )}
              </Select>
            </Form.Item>
            <Form.Item
              label={t("formLabels.gamesInfo.gamesDetails.first_score")}
              name="first_score"
              rules={[{ required: true, message: "Please enter the first score!" }]}
              >
              <Select onChange={(value) => setFirstScore(value)}>
                {rowData && rowData.home_competitor_id && (
                  <Select.Option value={rowData.home_competitor_id}>{rowData.home_competitor_name}</Select.Option>
                )}
                {rowData && rowData.away_competitor_id && (
                  <Select.Option value={rowData.away_competitor_id}>{rowData.away_competitor_name}</Select.Option>
                )}
              </Select>
            </Form.Item>
            <Form.Item
              label={t("formLabels.gamesInfo.gamesDetails.both_team_score")}
              name="both_team_score"
              valuePropName="checked"
              >
              <Checkbox 
                onChange={(e) => setBothTeamScore(e.target.checked)} // {{ edit_2 }}
              />
            </Form.Item>
            <Form.Item
              label={t("formLabels.gamesInfo.gamesDetails.game_goals")}
              name="game_goals"
              rules={[{ required: true, message: "Please enter the game goals!" }]}
              >
              <Input 
                type="number" 
                onChange={(e) => setGameGoals(e.target.value)} // {{ edit_3 }}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={processAmbiguousLoading} disabled={!competitorValue}>
              OK
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    {/* </Flex> */}
    </Layout>
  );
};

export default GamesWithoutEvents;
